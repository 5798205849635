import { autoinject, bindable, TaskQueue } from "aurelia-framework";
import { ISmileUser } from "../interfaces/ISmileUser";
import { DialogService } from "aurelia-dialog";
import { Router } from "aurelia-router";
import { SmileService } from "resources/services/SmileService";
import { RuntimeInfo } from "../../../resources/classes/RuntimeInfo";
import { DialogMessages } from "../../../resources/services/DialogMessages";
import { FhirService } from "../../../resources/services/FhirService";
import { fhirEnums } from "../../../resources/classes/fhir-enums";
import { User } from "./user";
import BundleType = fhirEnums.BundleType;
import { UserService } from "../../../resources/services/UserService";
import { MoveRole } from "./move-role";
import { ConfigService } from "resources/services/ConfigService";
import { NitTools } from "resources/classes/NursitTools";
import { UserCsvImport } from "./user-csv-import";

@autoinject
export class userList {
    users: ISmileUser[];
    _users: ISmileUser[];
    hashName: string = undefined;
    hashPass: string = undefined;
    generatedHash: string = undefined;
    hashResult: HTMLInputElement;
    passwordChangeExtensionName = "/needsPasswordChange";
    @bindable userFilterValue: string;

    constructor(protected dialogService: DialogService, protected taskQueue: TaskQueue,
        protected router: Router, protected smileApi: SmileService,
        public dialogMessages: DialogMessages, protected fhirService: FhirService
    ) { }

    async attached() {
        await this.loadUsers();
    }

    async updatePracitionerRoles(roles : fhir4.PractitionerRole[]) {
        const resultBundle: fhir4.Bundle = await this.fhirService.bundle(roles, fhirEnums.HTTPVerb.put, BundleType.transaction, false);
        const responses = resultBundle.entry?.map(o => o.response).filter(o => o.status.indexOf('200') == -1);
        if (responses.length > 0) {
            this.dialogMessages.prompt(`There have been errors when updating the Roles. Failed entries are written to the console`);
            console.warn(responses);
        } else {
            this.dialogMessages.prompt(`Users have been updated successfully`);
        }
    }

    /** removes from every practitioner the extension to be forced to change the password */
    async removeGlobalPWChange() {
        if (!await this.dialogMessages.confirm('Are you sure you want to remove the change-password from EVERY user?', 'Confirm', 'Yes', 'Not really!'))
            return;

        try {
            RuntimeInfo.IsLoading = true;
            
            const roles: fhir4.PractitionerRole[] = await this.fhirService.fetch(`PractitionerRole`);
            const updateResources = [];
            for (const role of roles) {
                const passwordChangeExtension = role?.extension?.find(o => o.url?.endsWith(this.passwordChangeExtensionName));
                if (!passwordChangeExtension) continue;

                role.extension = role.extension.filter(o => !o.url?.endsWith(this.passwordChangeExtensionName));
                if (role.extension.length === 0) {
                    delete role.extension;
                }

                updateResources.push(role);
            }

            if (updateResources.length === 0) {
                this.dialogMessages.prompt(`There are currently no users that have the change-password flag`);
                return;
            }

            if (!await this.dialogMessages.confirm(`The change-password will be removed from ${updateResources.length} users. Shall we proceed?`, 'Confirm', 'Yes', 'Not i changed my mind!'))
                return;

            await this.updatePracitionerRoles(updateResources);
        }
        catch (ex) {
            await this.dialogMessages.prompt(ex);
        }
        finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    /** add to every practitioner the extension to be forced to change the password */
    async forceGlobalPWChange() {
        if (!await this.dialogMessages.confirm('Are you sure you want to add the change-password flag to EVERY user?', 'Confirm', 'Yes', 'Maybe better not'))
            return;

        RuntimeInfo.IsLoading = true;
        try {
            const changePasswordUrl = `${NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader)}/${this.passwordChangeExtensionName}`;
            const bundle = await this.fhirService.fetch("PractitionerRole?active=true&_include=PractitionerRole:practitioner");
            const updateResources = [];        
            for (const _role of bundle.filter(o=>o.resourceType === 'PractitionerRole' && o.practitioner?.reference)) {
                const role : fhir4.PractitionerRole = _role;
                const prac : fhir4.Practitioner = bundle.find(o=>o.id == NitTools.StripId(role.practitioner.reference) && o.active);
                if (!prac) continue;

                // check if the practitioner is a pre-defined account and skip that ones
                const accountIdentifier = prac.identifier?.find(o=>o.system?.endsWith('/smile-account-id'));            
                if (['ROOT', 'ADMIN', 'EMBEDDED'].indexOf(accountIdentifier?.value?.toUpperCase()) > -1) continue;

                // ensure existence and correct type of role.extension property
                if (!NitTools.IsArray(role.extension))
                {
                    role.extension = [];
                }

                // don't process Roles that already have the extension assigned            
                const existing = role.extension.find(o=>o.url?.endsWith(this.passwordChangeExtensionName));
                if (existing?.valueString === 'true') continue;
                
                // remove any old extension of that type
                role.extension = role.extension.filter(o=>!o.url || !o.url.endsWith(this.passwordChangeExtensionName));
                role.extension.push({
                    url: changePasswordUrl,
                    valueString: 'true'
                });

                updateResources.push(role);        
            }

            if (!await this.dialogMessages.confirm(`The change-password flag will be set for ${updateResources.length}<br />You are really sure you want to do that`, 'Confirm')) return;        
            
            RuntimeInfo.IsLoading = true;
            await this.updatePracitionerRoles(updateResources);
        }
        catch(ex) {            
            console.warn(ex);
            this.dialogMessages.prompt(ex, 'ERROR', true);
        }
        finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    userFilterValueChanged(newValue) {
        if (!newValue) {
            this.users = this._users || [];
        } else {
            const s = newValue.toUpperCase();
            this.users = (this._users || []).filter(o => o.username?.toUpperCase().indexOf(s) > -1
                || o.familyName?.indexOf(s) > -1
                || o.givenName?.indexOf(s) > -1
                || `${o.familyName}, ${o.givenName}`.toUpperCase().indexOf(s) > -1
            );
        }
    }

    generateHash() {
        this.generatedHash = btoa(this.hashName + ":" + this.hashPass);
    }

    updateUserFilter() {

    }

    async csvImport() {
        this.dialogService.open({
            viewModel: UserCsvImport,
            model: {},
            lock: true
        })
    }

    moveRole() {
        if (!this.dialogService)
            alert("No Dialog Service found");
        let roleViews = ConfigService.cfg?.roleViews;
        let defaultDomains: string[] = [
            "controlling", "pharmacy",
            "nurse", "admin", "user", "trainee",
            "doctor", "therapy", "socialservice",
        ];

        if (roleViews) {
            defaultDomains.push(...roleViews.filter(rv => defaultDomains.indexOf(rv.role) === -1).map(o => o.role));
        }

        defaultDomains = defaultDomains.sort();

        this.dialogService.open({
            viewModel: MoveRole,
            model: {
                from: "user",
                to: "nurse",
                domains: defaultDomains
            },
            lock: true
        })
            .whenClosed(async result => {
                if (result.wasCancelled || result.output.from === result.output.to) return;
                console.warn(`Mapping from "${result.output.from}" to "${result.output.to}"`);
                try {
                    RuntimeInfo.IsLoading = true;
                    const pracs: fhir4.Practitioner[] = await this.fhirService.fetch(`Practitioner?identifier=${result.output.from}`);
                    for (const prac of pracs) {
                        for (const ident of prac.identifier) {
                            if (ident.system?.indexOf("/smile-user-role") === -1 || ident.value != result.output.from) continue;
                            ident.value = result.output.to;
                        }
                    }

                    let hasErrors = false;
                    const bundle: fhir4.Bundle = await this.fhirService.bundle(pracs, fhirEnums.HTTPVerb.put, BundleType.transaction, false);
                    if (NitTools.IsArray(bundle.entry)) {
                        const errorEntries = bundle.entry.filter(e => e.response?.status.indexOf('200') == -1);
                        hasErrors = errorEntries.length > 0;
                        for (const err of errorEntries) {
                            console.warn(err.response);
                        }
                    }

                    if (hasErrors) {
                        alert("There have been some errors updating the Roles.\nTake a look at the console for more details!");
                    }
                }
                catch (ex) {
                    console.warn(ex);
                    alert(`Error running Update:\n{ex}`);
                }
                finally {
                    RuntimeInfo.IsLoading = false;
                }
            })
    }

    async fixPermissions() {
        try {
            let fixCount = 0;
            RuntimeInfo.IsLoading = true;
            const practitioners: any[] = await this.fhirService.fetch('Practitioner?active=true');
            const updatePractitioners = [];
            for (const user of this._users) {
                if (!user.authorities) {
                    user.authorities = User.DefaultUserAuthorities;
                }

                user.authorities = user.authorities.sort((a, b) => {
                    return a.permission.localeCompare(b.permission);
                });

                const backup = JSON.stringify(user);

                let practitioner: any;
                for (const prac of practitioners.filter(o => o.identifier)) {
                    const smileUserPID = prac.identifier.find(o => o.system && o.system.endsWith('/smile-account-id'));
                    if (typeof smileUserPID !== "undefined") {
                        if (smileUserPID.value.toUpperCase() === user.username.toUpperCase()) {
                            practitioner = prac;
                            break;
                        }
                    }
                }

                if (practitioner && practitioner.identifier) {

                    const roleIdend = UserService.GetUserRole(practitioner);
                    if (roleIdend) {
                        switch (roleIdend) {
                            default:
                            case "doctor":
                            case "trainee":
                            case "user":
                                user.authorities = [
                                    { permission: "ROLE_FHIR_CLIENT" },
                                    { permission: "FHIR_CAPABILITIES" },
                                    { permission: "FHIR_ALL_WRITE" },
                                    { permission: "FHIR_BATCH" },
                                    { permission: "FHIR_TRANSACTION" },
                                    { permission: "FHIR_ALL_READ" },
                                    { permission: "CHANGE_OWN_PASSWORD" },
                                    { permission: "CHANGE_OWN_DEFAULT_LAUNCH_CONTEXTS" },
                                    { permission: "VIEW_USERS" },
                                    { permission: "FHIR_ALL_DELETE" },
                                    { permission: "FHIR_META_OPERATIONS_SUPERUSER" },
                                    { permission: "FHIR_OP_ENCOUNTER_EVERYTHING" },
                                    { permission: "FHIR_OP_PATIENT_EVERYTHING" },
                                    { permission: "ACCESS_ADMIN_JSON" }
                                ];
                                break;
                            case "admin":
                                break;
                            case "designer":
                                user.authorities = [
                                    { permission: "ROLE_FHIR_CLIENT" },
                                    { permission: "FHIR_CAPABILITIES" },
                                    { permission: "CHANGE_OWN_PASSWORD" },
                                    { permission: "FHIR_ALL_WRITE" },
                                    { permission: "FHIR_ALL_DELETE" },
                                    { permission: "FHIR_ALL_READ" },
                                    { permission: "ACCESS_ADMIN_JSON" },
                                    { permission: "FHIR_TRANSACTION" }];
                                break;
                        }
                    }
                } else { // no practitioner? Update anyway with default stuff when it's not a superuser
                    if (typeof user.authorities.find(o => o.permission == "ROLE_SUPERUSER") == "undefined")
                        user.authorities = [
                            { permission: "ROLE_FHIR_CLIENT" },
                            { permission: "CHANGE_OWN_PASSWORD" },
                            { permission: "CHANGE_OWN_DEFAULT_LAUNCH_CONTEXTS" },
                            { permission: "VIEW_USERS" },
                            { permission: "FHIR_CAPABILITIES" },
                            { permission: "FHIR_ALL_WRITE" },
                            { permission: "FHIR_ALL_DELETE" },
                            { permission: "FHIR_ALL_READ" },
                            { permission: "FHIR_BATCH" },
                            { permission: "ACCESS_ADMIN_JSON" },
                            { permission: "FHIR_META_OPERATIONS_SUPERUSER" },
                            { permission: "FHIR_OP_ENCOUNTER_EVERYTHING" },
                            { permission: "FHIR_OP_PATIENT_EVERYTHING" },
                            { permission: "FHIR_TRANSACTION" }];
                    break;
                }

                user.authorities = user.authorities.sort((a, b) => {
                    return a.permission.localeCompare(b.permission);
                });

                if (backup !== JSON.stringify(user)) {
                    await this.smileApi.updateUser(user);
                    fixCount++;
                }

                /* now update the practitioner to include the user pid:
                {
                    system: RuntimeInfo.SystemHeader + '/smile-user-pid',
                        value: this.userData.pid
                }*/
                // const practitioners = await this.fhirService.fetch(`Practitioner?identifier=${user.username}`);

                if (practitioner) {
                    const pidIdentifier = practitioner.identifier.find(o => o.system && o.system.endsWith('/smile-user-pid'));
                    if (typeof pidIdentifier === "undefined") {
                        practitioner.identifier.push({
                            system: RuntimeInfo.SystemHeader + '/smile-user-pid',
                            value: String(user.pid)
                        });

                        updatePractitioners.push(practitioner);
                    } else if (pidIdentifier.value !== String(user.pid)) {
                        pidIdentifier.value = String(user.pid);
                        updatePractitioners.push(practitioner);
                    }
                }
            }

            if (updatePractitioners.length > 0)
                await this.fhirService.bundle(updatePractitioners, fhirEnums.HTTPVerb.put, BundleType.transaction);

            if (updatePractitioners.length === 0 && fixCount === 0)
                this.dialogMessages.prompt('Finished fixing permissions<br />No Permission or Practitioner needed update.<br />All in well state.', 'Success', false);
            else
                this.dialogMessages.prompt(`Finished fixing ${fixCount} permissions and ${updatePractitioners.length} Pracitioner-Resources`, 'Success', false);

        } catch (error) {
            let msg = error.message || JSON.stringify(error);
            console.warn(msg);
            this.dialogMessages.prompt(msg, 'Error fixing', true);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    clearHash() {
        this.generatedHash = undefined;
        this.hashName = undefined;
        this.hashPass = undefined;
    }

    selectHash() {
        this.hashResult.focus();
        this.hashResult.selectionStart = 0;
        this.hashResult.selectionEnd = this.hashResult.value.length;
    }

    loadUsers() {
        this.smileApi.getUsers(0, 250).then((users) => {
            this._users = users;
            this.userFilterValue = undefined;
            this.userFilterValueChanged(undefined);
        });
    }

    addUser() {
        this.router.navigateToRoute("user");
    }

    editUser(username) {
        this.router.navigateToRoute("user", { user: username });
    }
}
